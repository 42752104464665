import * as React from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FaTag, FaCalendar } from "react-icons/fa/"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Bio from "../components/bio"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const tags = data.allMarkdownRemark.group

  if (tags.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="タグ" />
        <p>
          何もありません
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="ホーム" />
      <h1>フリーペーパーナイスガイのホームページ</h1>
      <ul
        style={{
          listStyle: `none`,
          padding: `0.5em 1em`,
          color: `#5d627b`,
          background: `pink`,
          boxShadow: `10px 10px 5px rgba(0, 0, 0, 5)`,
          float: `left`,
          marginRight: `10px`,
      }}
      >
        <h2>ナイスガイについて</h2>
          <li>
            <p>
            ナイスガイとは、巷で人気のフリーペーパーです。<br />
            結構人気があります。
            </p>
          </li>
          <li>
          <Link to="https://twitter.com/niceguy_staff">ツイッター</Link>
          </li>
          <li>
          <Link to="https://www.instagram.com/niceguy_staff/">インスタグラム</Link>
          </li>
          <li>
          <Link to="https://niceguy.theshop.jp/">ショップ</Link>
          </li>
      </ul>
      {tags.map(tag => (
        <ul 
          key={tag.fieldValue}
          style={{
            listStyle: `none`,
            padding: `0.5em 1em`,
            color: `#5d627b`,
            background: `pink`,
            boxShadow: `10px 10px 5px rgba(0, 0, 0, 5)`,
            float: `left`,
            marginRight: `10px`,
          }}
         >
          <h2>{tag.fieldValue}</h2>
          {tag.edges.map(({ node }) => {
            const { slug } = node.fields
            const { title } = node.frontmatter
            return (
              <li key={slug}>
                <Link to={slug}>{title}</Link>
              </li>
            )
          })}
        </ul>
      ))}
      <ul
        style={{
          listStyle: `none`,
          listStyle: `none`,
          padding: `0.5em 1em`,
          color: `#5d627b`,
          background: `pink`,
          boxShadow: `10px 10px 5px rgba(0, 0, 0, 5)`,
          float: `left`,
          clear: `both`,
        }}
      >
        <h2>有料版出ました！！</h2>
        <p>
          有料版のナイスガイが出ていますよ。<br />
          1000円で買うことができますよ。<br />
          結構人気があります。
        </p>
        <li>
          <StaticImage
            src="../images/sniceguy.jpg"
            alt="Profile picture"
            style={{
              maxWidth: `100%`,
              maxHeight: `auto`,
            }}
          />
        </li>
      </ul>
      <ul
        style={{
          listStyle: `none`,
          listStyle: `none`,
          padding: `0.5em 1em`,
          color: `#5d627b`,
          background: `pink`,
          boxShadow: `10px 10px 5px rgba(0, 0, 0, 5)`,
          clear: `both`,
        }}
      >
        <h2>愛とFAX特集にジョージ・ハダケルが登場</h2>
        <p>
          愛とFAX特集にジョージ・ハダケルが出ていますよ。<br />
          結構人気があります。
        </p>
        <li>
          <StaticImage
            src="../images/hadakeru72ppi.jpg"
            alt="Profile picture"
            style={{
              maxWidth: `100%`,
              maxHeight: `auto`,
            }}
          />
        </li>
      </ul>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
  allMarkdownRemark(
    limit: 2000
    sort: { fields: [frontmatter___date], order: DESC }
  ) {
    group(field: frontmatter___tags) {
      fieldValue
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }  
    }
  }
}
`
